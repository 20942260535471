import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Spectrum',
    component: () => import('@/views/Spectrum')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/notFound/404')
  },
  {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
